<script lang="ts">
	import type { Image$data } from '$houdini';

	export let imageData: Image$data;
	export let fetchPriority: 'low' | 'high' | 'auto' = 'auto';
	export let alt = '';
	export let loading: 'lazy' | 'eager' | null = null;
	export let decoding: 'sync' | 'async' | 'auto' = 'auto';

	const SIZES = Object.freeze({
		sm: 320,
		md: 640,
		lg: 1024,
		xl: 1280
	});

	const _FORBIDDEN_KEYS = Object.freeze(['id', 'url', 'size']);
	$: cleanImageData = Object.entries(imageData).filter(
		([key, _]) => !_FORBIDDEN_KEYS.includes(key)
	);
	$: imageSizes = cleanImageData
		.filter(([key, value]) => (imageData.width ? SIZES[key] < imageData.width : true))
		.map(([key, value]) => `${value} ${SIZES[key]}w`);

	let hasError = false;
</script>

{#if imageData}
	<img
		{fetchPriority}
		{loading}
		{decoding}
		src={imageData.url}
		srcset={hasError
			? ''
			: [...imageSizes, ...(imageData.width ? [`${imageData.url} ${imageData.width}w`] : [])].join(
					','
			  )}
		on:error={(e) => {
			hasError = true;
		}}
		{alt}
		{...$$restProps}
	/>
{/if}
